import React from 'react'
import { CampaignCard, Text } from '@/components/common'
import { InterstitialCampaignCard } from '@/components/common/CampaignCard'
import { Campaign } from '@/services/api/campaign'
import classNames from 'classnames'
import useSiteAssets from '@/services/hooks/useSiteAssets/useSiteAssets'
import { whiteLabelUtil } from '@/utils'

interface Props {
  campaigns: Campaign[]
  title: string
  subtitle?: string
}

export const CampaignSection: React.FC<Props> = ({
  campaigns,
  title,
  subtitle,
  ...rest
}) => {
  const { domain } = useSiteAssets()

  return (
    <div className="max-w-[1168px] mb-8 px-4 w-full" {...rest}>
      <Text
        as="h3"
        preset="heading.lg md:heading.xl"
        className="md:inline-block md:mr-2"
      >
        {title}
      </Text>
      {subtitle && (
        <Text
          as="p"
          preset="heading.sm md:heading.lg"
          className="font-normal text-gray-3 md:inline-block"
        >
          {subtitle}
        </Text>
      )}
      <div
        className={classNames('grid gap-6 mt-6', {
          'sm:grid-cols-2': campaigns.length >= 2,
          'lg:grid-cols-3': campaigns.length >= 3,
        })}
      >
        {campaigns.map((campaign) => {
          if (!whiteLabelUtil.isAllowed(campaign, domain)) {
            return (
              <InterstitialCampaignCard
                key={campaign.slug}
                campaign={campaign}
                domain={whiteLabelUtil.getDomainFromSlug(campaign.slug)}
                layout={campaigns.length === 1 ? 'horizontal' : 'vertical'}
              />
            )
          }

          return (
            <CampaignCard
              key={campaign.slug}
              campaign={campaign}
              layout={campaigns.length === 1 ? 'horizontal' : 'vertical'}
              noDescription
            />
          )
        })}
      </div>
    </div>
  )
}

export default CampaignSection
